<template>
  <div class="mangerPage">
    <div class="main">
      <div class="top_view">
        <a-button
          v-if="true"
          type="primary"
          class="addBtn"
          style="opacity:0;cursor:default;"
          >隐藏</a-button
        >
        <div>
          <a-select
            style="width: 140px; margin-right: 20px;"
            v-model="statusValue"
            placeholder="请选择状态"
            @change="changStatusValue"
          >
            <a-select-option
              v-for="(items, index) in statusList"
              :key="index"
              :value="items.value"
            >
              {{ items.title }}
            </a-select-option>
          </a-select>
          <a-input-search
            class="search_view"
            v-model="searchValue"
            placeholder="请输入腕表名称或型号查询"
            enter-button
            allow-clear
            @search="onSearchBanner"
          />
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        bordered
        :scroll="{ x: 1400 }"
        :pagination="false"
        :rowKey="record => record.bannerId"
        :loading="tableLoad"
      >
        <template slot="jumpName" slot-scope="text, record">
          <span v-if="record.jumpName == null">-</span>
          <span v-else>{{ record.jumpName }}</span>
        </template>
        <template slot="showStatus" slot-scope="text, record">
          <template v-if="record.bindingStatus == 0">
            已解绑
          </template>
          <template
            v-if="record.bindingStatus == 1 && record.activeStatus == 3"
          >
            质保已过
          </template>
          <template
            v-if="record.bindingStatus == 1 && record.activeStatus == 2"
          >
            已延保
          </template>
          <template
            v-if="record.bindingStatus == 1 && record.activeStatus == 1"
          >
            已激活
          </template>
          <template
            v-if="record.bindingStatus == 1 && record.activeStatus == 0"
          >
            待激活
          </template>
        </template>
        <template slot="action" slot-scope="text, record">
          <template v-if="record.bindingStatus == 1">
            <a-popconfirm
              title="确定解绑吗？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="RemoveBindingStatus(record)"
            >
              <a-button class="pointer">
                解绑
              </a-button>
            </a-popconfirm>
          </template>

          <!-- <template v-if="record.bindingStatus == 1 && record.approveStatus == 0">
            <a-button class="pointer" @click="approveReject(record)">拒绝</a-button>
          </template>

          <template v-if="record.bindingStatus == 1 && record.approveStatus == 0">
            <a-button class="pointer" @click="approvePass(record)">同意</a-button>
          </template>

          <template v-if="record.bindingStatus == 1 && record.approveStatus == 1 && record.activeStatus == 0">
            <a-button class="pointer" @click="activeCard(record)">激活保卡</a-button>
          </template>

          <template v-if="record.bindingStatus == 1 && record.approveStatus == 1 && record.activeStatus == 1">
            <a-button class="pointer" @click="extendCard(record)">延长质保</a-button>
          </template> -->
        </template>
      </a-table>
      <div class="page_warp">
        <a-pagination
          class="pagination"
          v-model="page.current"
          :total="page.total"
          show-less-items
          :defaultPageSize="page.size"
          @change="pageCurrentChange"
        />
      </div>
    </div>
    <a-modal
      title="提示"
      :visible="visible"
      okText="确定"
      cancelText="取消"
      @ok="handleOk(formData.watchRegisterId)"
      @cancel="handleCancel"
    >
      <a-form-model
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :model="formData"
        ref="userForm"
        :rules="rules"
      >
        <a-form-model-item label="原因" prop="rejectReason">
          <a-select v-model="formData.rejectReason" placeholder="请选择">
            <a-select-option :value="1">
              序列号不存在
            </a-select-option>
            <a-select-option :value="2">
              质保编号不存在
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 激活保卡弹出框 -->
    <a-modal
      title="激活保卡"
      :visible="activeVisible"
      okText="确定"
      cancelText="取消"
      @ok="activeOk(form.watchRegisterId)"
      @cancel="activeCancel"
    >
      <a-form-model
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :model="form"
        ref="userForm"
        :rules="rules"
      >
        <a-form-model-item label="激活日期" prop="activeDate">
          <div>
            <a-date-picker
              format="YYYY-MM-DD"
              @change="onChange"
              placeholder="请选择"
              v-model="form.activeDate"
            >
            </a-date-picker>
          </div>
          <p style="font-size:10px;line-height:15px;">
            激活日期，即腕表激活的起始日期。选择激活日期并确认后，列表中的质保过期时间会根据所选择的激活日期自动显示为2年后。
          </p>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 延长质保弹出框 -->
    <a-modal
      title="延长质保"
      :visible="extendVisible"
      okText="确定"
      cancelText="取消"
      @ok="extendOk(extendForm.watchRegisterId)"
      @cancel="extendCancel"
    >
      <a-form-model
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :model="extendForm"
        ref="userForm"
        :rules="rules"
      >
        <a-form-model-item label="质保过期时间" prop="extendDate">
          <div>
            <a-date-picker
              format="YYYY-MM-DD"
              @change="extendOnChange"
              placeholder="请选择"
              v-model="extendForm.extendDate"
            >
            </a-date-picker>
          </div>
          <p style="font-size:10px;line-height:15px;">
            质保过期时间，即腕表腕表质保过期的终止时间。选择质保过期时间并确认后，列表中的质保过期时间会进行更新。
          </p>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import API from "@/request";
import moment from "moment";
import upload from "@/utils/upload";
import { compressImage } from "../../assets/staticData/compressImage";
import { mixin } from "../commonData";
import { message } from "ant-design-vue";
export default {
  mixins: [mixin],
  name: "mangerPage",
  data() {
    return {
      ...this.data()
    };
  },
  methods: {
    data() {
      return {
        isBannerName: false, //轮播图区域名称显示
        bannerAreaDetail: {}, //轮播图区域信息
        isShowBannerArea: true, //显示input
        coverList: [], // 上传轮播图列表
        previewImage: "", // 首页图预览字段
        previewVisible: false, //控制首页图预览显示隐藏字段
        visible: false, //拒绝弹框
        activeVisible: false, //激活保卡弹框
        extendVisible: false, //延长保卡弹框
        loading: false, // 封面图上传加载效果控制字段
        tableLoad: false, // 表格数据加载效果
        isSubmitInfo: false, //点击新建可能会重复提交数据
        formData: {
          // 拒绝表单数据
          rejectReason: "", //解绑腕表原因
          watchRegisterId: ""
        },
        form: {
          // 激活保卡表单数据
          activeDate: "", //解绑腕表原因
          watchRegisterId: ""
        },
        extendForm: {
          // 激活保卡表单数据
          extendDate: "", //解绑腕表原因
          watchRegisterId: ""
        },
        userId: this.$route.query.userId,
        page: {
          total: 0,
          current: 1,
          size: 10,
          userId: this.$route.query.userId,
          keyword: ""
        },
        tableData: [], // 表格数据
        changStatus: "", // 表格右上类型筛选绑定字段
        searchValue: "",
        statusList: [
          {
            value: "",
            title: "显示全部"
          },
          // {
          //   value: "1",
          //   title: "待验证",
          // },
          // {
          //   value: "2",
          //   title: "验证失败",
          // },
          {
            value: "3",
            title: "待激活"
          },
          {
            value: "4",
            title: "已激活"
          },
          {
            value: "5",
            title: "已延保"
          },
          {
            value: "6",
            title: "已解绑"
          }
        ], //搜索状态
        statusValue: "",
        typeValue: 0,
        columns: [
          {
            title: "序号",
            dataIndex: "watchRegisterId"
          },
          {
            title: "腕表名称",
            dataIndex: "name"
          },
          {
            title: "腕表型号",
            dataIndex: "model"
          },
          {
            title: "图片",
            dataIndex: "mainImg",
            align: "center",
            width: 200,
            customRender: (text, record) => {
              if (text != "" && text != null) {
                var arr = text.split(",");
                var index = arr.findIndex(item => {
                  return this.valiIsNotVideo(item);
                });
                console.log(text);
                if (index != -1) {
                  return (
                    <img
                      src={arr[index]}
                      class="list_img"
                      style="width:100%;height:140px;object-fit: contain;"
                    />
                  );
                } else {
                  return <span>-</span>;
                }
              } else {
                return <span>-</span>;
              }
            }
          },
          {
            title: "腕表序列号",
            dataIndex: "serialNo"
          },
          {
            title: "质保编号",
            dataIndex: "qualityAssuranceNo"
          },
          {
            title: "状态",
            dataIndex: "activeStatus",
            scopedSlots: { customRender: "showStatus" }
          },
          {
            title: "激活日期",
            dataIndex: "activeTime"
          },
          {
            title: "质保过期时间",
            dataIndex: "qualityAssuranceExpireTime"
          },
          {
            title: "操作",
            dataIndex: "watchBoxNums",
            width: "250px",
            scopedSlots: { customRender: "action" }
          }
        ],
        isAdd: false, // 控制新增表单弹窗控制字段
        // header_title: "新增",
        isShowPriview: false
      };
    },
    async approveReject(key) {
      this.formData = {
        watchRegisterId: key.watchRegisterId
      };
      this.visible = true;
    },
    async approvePass(record) {
      if (record.model) {
        let res = await API.UpdateApproveStatus({
          approveStatus: 1,
          watchRegisterId: record.watchRegisterId
        });
        if (res.data.code === "0000") {
          this.$message.success("验证同意成功");
          this.getList();
        }
      } else {
        alert(
          "若腕表型号存在，可前往“腕表主数据管理”模块中将腕表序列号维护在对应型号的腕表信息中"
        );
      }
    },

    async activeCard(key) {
      this.form = {
        watchRegisterId: key.watchRegisterId
      };
      this.activeVisible = true;
    },

    handleOk(watchRegisterId) {
      this.$refs.userForm.validate(async valid => {
        if (valid) {
          let res;
          res = await API.UpdateApproveStatus({
            approveStatus: 2,
            rejectReason: this.formData.rejectReason,
            watchRegisterId: watchRegisterId
          });
          if (res.data.code === "0000") {
            this.$message.success("拒绝成功");
            this.visible = false;
            this.formData = {
              rejectReason: ""
            };
          }

          await this.getList();
        }
      });
    },

    activeOk(watchRegisterId) {
      this.$refs.userForm.validate(async valid => {
        if (valid) {
          if (!this.form.activeDate) {
            message.warning("请选择日期");
            return;
          }
          let res;
          res = await API.updateActivityStatus({
            activeStatus: 1,
            updateTime: moment(this.form.activeDate).valueOf(),
            watchRegisterId: watchRegisterId
          });
          if (res.data.code === "0000") {
            this.$message.success("激活成功");
            this.activeVisible = false;
            this.form = {
              activeDate: ""
            };
          }

          await this.getList();
        }
      });
    },

    activeCancel() {
      this.form = {
        activeDate: ""
      };
      this.activeVisible = false;
    },

    /**
     * 时间选择
     */
    onChange(dates) {
      this.form.activeDate = dates.format("YYYY-MM-DD");
    },

    extendOk(watchRegisterId) {
      this.$refs.userForm.validate(async valid => {
        if (valid) {
          let res;
          res = await API.updateActivityStatus({
            activeStatus: 2,
            updateTime: moment(this.extendForm.extendDate).valueOf(),
            watchRegisterId: watchRegisterId
          });
          if (res.data.code === "0000") {
            this.$message.success("延长质保成功");
            this.extendVisible = false;
            this.extendForm = {
              extendDate: ""
            };
          }
          await this.getList();
        }
      });
    },

    extendCancel() {
      this.extendForm = {
        extendDate: ""
      };
      this.extendVisible = false;
    },

    /**
     * 延长质保时间选择
     */
    extendOnChange(dates) {
      this.extendForm.extendDate = dates.format("YYYY-MM-DD");
    },

    async extendCard(key) {
      this.extendForm = {
        watchRegisterId: key.watchRegisterId
      };
      this.extendVisible = true;
    },

    handleCancel() {
      if (this.$refs.userForm != undefined) {
        this.$refs.userForm.resetFields();
      }
      this.formData = {
        account: "",
        userName: "",
        passWord: "",
        userId: ""
      };
      this.visible = false;
    },

    async UpdateApprovePass(record) {
      let res = await API.UpdateApproveStatus({
        approveStatus: 1,
        watchRegisterId: record.watchRegisterId
      });
      if (res.data.code === "0000") {
        this.getList();
      }
    },
    async RemoveBindingStatus(record) {
      let res = await API.RemoveBindingStatus({
        watchRegisterId: record.watchRegisterId
      });
      if (res.data.code === "0000") {
        this.getList();
      }
    },

    resetData() {
      Object.assign(this.$data, this.data());
    },
    pageCurrentChange(val) {
      // 分页页码变化
      this.page.current = val;
      this.getList();
    },
    goUrl(userId) {
      // this.$router.replace('/bannerLevel')
      this.$router.push({ path: "/watchBox", query: { userId: userId } });
    },
    //状态搜索
    changStatusValue(value) {
      this.statusValue = value;
      this.getList();
    },
    dateFormat(val) {
      return moment(val).format("YYYY年MM月DD日 a HH");
    },
    //名称搜索 轮播图
    onSearchBanner(value) {
      this.getList();
    },
    /**
     * 弹出框提交数据
     */
    async submitData(val) {
      let validateJump;
      this.formData.materialType = 0;
      console.log(this.formData);

      // 组装提交数据
      this.$refs.contentForm.validate(async valid => {
        if (valid) {
          let res;

          let message;

          if (!this.formData.bannerId) {
            if (this.isSubmitInfo) {
              // this.$message.warning("新增中，请勿重复提交！");
              return;
            }
            this.isSubmitInfo = true;
            res = await API.addBanner(this.formData).catch(err => {
              this.isSubmitInfo = false;
            });
            message = "新增成功";
          } else {
            res = await API.editBanner(this.formData);
            message = "修改成功";
          }
          console.log(res.data);
          if (res.data.code === "0000") {
            this.$message.success(message);
            this.isAdd = false;
            this.formData = {
              materials: "",
              jump: "",
              location: this.typeValue,
              name: "",
              sort: "",
              type: 1
            };
            this.coverList = [];
            this.getList();
          }
        }
      });
    },
    handleChange() {},
    // 获取表格数据
    async getList(
      current = this.page.current,
      keyword = this.searchValue,
      size = this.page.size,
      status = this.statusValue,
      userId = this.userId
    ) {
      this.tableLoad = true;
      let res = await API.getWatchBox({
        current,
        keyword,
        size,
        status,
        userId
      });
      if (res.data.code === "0000") {
        let data = res.data.data;
        this.tableData = data.records;
        this.tableLoad = false;
        this.page = {
          // 设置分页信息
          current: data.current,
          total: data.total,
          size: data.size
        };
      }
    }
  },
  mounted() {
    this.getList(); // 请求表格数据
  }
};
</script>

<style lang="less" scoped>
/deep/.has-error .ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #f5222d !important;
}
.pointer {
  margin-right: 8px;
}

.main {
  margin: 24px;
  background: #ffffff;
  padding: 24px;
}

.modal_content {
  max-height: 600px;
  overflow: auto;
}
.search_View {
  width: 230px;
}
.top_view {
  display: flex;
  justify-content: space-between;
  height: 48px;
}
.search_view {
  width: 280px;
  margin-right: 20px;
}
</style>
